import { fetchAuthSession } from 'aws-amplify/auth';
import { create } from 'zustand';
import i18next from 'i18next';
import axios, { AxiosProgressEvent } from 'axios';

const useUploadFile = create<{
  uploadFile: (
    wssApiUrl: string,
    session_id:string,
    file:File,
    reportProgress: (progress:number) => void,
    dispatch: (url: string) => void,
  ) => Promise<void>;
}>(() => {
  const uploadFile = async (
    wssApiUrl: string,
    session_id: string,
    file:File,
    reportProgress: (progress:number) => void,
    dispatch: (url: string) => void,
  ) => {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};



    const onUploadProgress = (progressEvent:AxiosProgressEvent) => {
      const { loaded, total } = progressEvent;
      if (total){
        const percent = Math.floor((loaded * 100) / total);
        reportProgress(percent);
        if (percent < 100) {
          console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
        }
      }
      
    };
    

    return new Promise<void>((resolve, reject) => {
      const ws = new WebSocket(wssApiUrl,["websocket", ''+idToken]);
      
      ws.onopen = () => {
        ws.send(JSON.stringify({action: "UploadAttachments",session_id:session_id}));
      };

      ws.onmessage = (message) => {
        try {
          if (message.data === '') {
            return;
          }

          const data = JSON.parse(message.data);
          console.log("useUploadFile:data",data);

          if (data.type && data.type==='attachments'){
            console.log("useUploadFile:processing",data.session_id),data.presigned_post;
            const presigned_post={...data.presigned_post};
            presigned_post.fields.key=presigned_post.fields.key.replace('${filename}',file.name)
            const fields = presigned_post.fields;

            const formData = new FormData();
            Object.keys(fields).forEach(k => {
              const v = fields[k];
              formData.append(k, v);
            });
            formData.append("file", file);

            axios.post(presigned_post.url, formData, {
                headers: { "Content-Type": "multipart/form-data" },onUploadProgress,
            }).then((response)=>{
                
                const parser = new DOMParser();
                //const xml = parser.parseFromString(response.data, 'text/xml');
                //const bucket=xml.querySelector('PostResponse')?.getAttribute('Bucket');
                const bucket=presigned_post.url.replace('https://','').split('.')[0];
                console.log("axios.putfile:",response,presigned_post.url+presigned_post.fields.key,bucket);
               //resolve(presigned_post.url+presigned_post.fields.key); //TODO: DEBUG: resolve jest w on close (?)  albo wykona się
                dispatch('s3://'+bucket+'/'+presigned_post.fields.key);
                ws.close();
                resolve();
            }).catch((reason)=>{
                console.error(reason);
            });
            
            ws.close();
          }  else {
            console.error('error.predict.invalidResponse',data);
            ws.close();            
          }
        } catch (e) {
          console.error(e);
          reject(i18next.t('error.predict.general'));
        }
      };

      ws.onerror = (e) => {
        ws.close();
        console.error(e);
        reject(i18next.t('error.predict.general'));
      };
      // ws.onclose = () => {
      //   resolve();
      // };
    });
  };

  return {
    uploadFile,
  };
});
export default useUploadFile;
