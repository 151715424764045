import { FaFileAlt,FaLink,FaDragon,FaDatabase } from 'react-icons/fa';
import { AiFillThunderbolt } from 'react-icons/ai';
import { BaseProps } from '../@types/common';
//import { Model } from '../@types/conversation';
import Button from './Button';
import * as APITypes from "../API";
import { ReferencesMeta, ReferenceMeta } from '../@types/conversation';

type Props = BaseProps & {
  references:ReferencesMeta| undefined;
  openUrl:(url: string) => void;
};



const References: React.FC<Props> = (props) => {
  const buttonClass =
    'flex-1 min-w-40 flex items-center rounded-lg p-2 justify-center whitespace-nowrap';
  
  return(
    <div>
      <div className="justify-center rounded-lg border bg-gray-200 p-1 text-sm flex flex-wrap">

      {props.references?.map((reference:ReferenceMeta) =>{
          
            return (
              <Button
                className={`${buttonClass} ${
                   'bg-aws-paper text-gray-500 m-1'
                }`}
                icon={<FaFileAlt />}
                title={reference.text}
                key={reference.metadata.resource_name}
                onClick={() => props.openUrl(reference.metadata.url)}
                children={<span>{reference.metadata.resource_name}</span>}></Button>
            )
          })}

      </div>
    </div>
  );
};

export default References;
