import { useCallback, useEffect, useMemo, useState } from 'react';
import useConversationApi from './useConversationApi';
import { produce } from 'immer';
import {
  Content,
  MessageContent,
  MessageContentWithChildren,
  MessageMap,
  //Model,
  //ModelDefinition,
  PostMessageRequest,
  Conversation,
  ConversationMeta,
  Role,
  ReferencesMeta,
} from '../@types/conversation';
import useConversation from './useConversation';
import { create } from 'zustand';
import usePostMessageStreaming from './usePostMessageStreaming';
import useUploadFile from './useUploadFile';
import useSnackbar from './useSnackbar';
import { useNavigate } from 'react-router-dom';
import { ulid } from 'ulid';
import { convertMessageMapToArray } from '../utils/MessageUtils';
import { useTranslation } from 'react-i18next';
import awsmobile from '../configs/aws-exports';
import * as APITypes from "../API";
import { listEndpoints } from '../configs/queries';
import { v4 as uuid } from 'uuid';

type ChatStateType = {
  [id: string]: MessageMap;
};

const NEW_MESSAGE_ID = {
  USER: 'new-message',
  ASSISTANT: 'new-message-assistant',
};
const USE_STREAMING: boolean =
  awsmobile.VITE_APP_USE_STREAMING === 'true';


const useChatState = create<{
  conversationId: string;
  setConversationId: (s: string) => void;
  postingMessage: boolean;
  setPostingMessage: (b: boolean) => void;
  chats: ChatStateType;
  setMessages: (id: string, messageMap: MessageMap) => void;
  copyMessages: (fromId: string, toId: string) => void;
  pushMessage: ( id: string, parentMessageId: string | null, currentMessageId: string, content: MessageContent, endpointName:string ) => void;
  removeMessage: (id: string, messageId: string) => void;
  editMessage: (id: string, messageId: string, content: string, references:ReferencesMeta) => void;
  getMessages: (id: string, currentMessageId: string) => MessageContentWithChildren[]; currentMessageId: string; 
  setCurrentMessageId: (s: string) => void; isGeneratedTitle: boolean;
  setIsGeneratedTitle: (b: boolean) => void;
  getPostedModelName: () => string;
}>((set, get) => {
  return {
    conversationId: '',
    setConversationId: (s) => {
      set(() => {
        return {
          conversationId: s,
        };
      });
    },
    postingMessage: false,
    setPostingMessage: (b) => {
      set(() => ({
        postingMessage: b,
      }));
    },
    chats: {},
    setMessages: (id: string, messageMap: MessageMap) => {
      set((state) => ({
        lastMessages: messageMap,
        chats: produce(state.chats, (draft: { [id: string]: MessageMap; }) => {
          draft[id] = messageMap;
        }),
      }));
    },

    copyMessages: (fromId: string, toId: string) => {
      set((state) => ({
        chats: produce(state.chats, (draft: { [id: string]: any; }) => {
          console.log("useChat:copyMessages",fromId,toId,draft);
          draft[toId] = JSON.parse(JSON.stringify(draft[fromId]));
        }),
      }));
    },
    pushMessage: (
      id: string,
      parentMessageId: string | null,
      currentMessageId: string,
      content: MessageContent,
      endpointName: string 
    ) => {
      set((state) => ({
        chats: produce(state.chats, (draft: { [id: string]: { [messageId: string]: { children: string[]; parent: string; role: Role; content: Content; endpointName: string; }; }; }) => {
          console.log("pushMessage:id:1",id,parentMessageId,currentMessageId)
          
          if (draft[id] && parentMessageId && parentMessageId !== 'system') {
            draft[id][parentMessageId] = {
              ...draft[id][parentMessageId],
              children: [
                ...draft[id][parentMessageId].children,
                currentMessageId,
              ],
            };
            draft[id][currentMessageId] = {
              ...content,
              parent: parentMessageId,
              children: [],
              endpointName: endpointName
            };
          } 
          else {
            console.log("pushMessage:id:2",id,parentMessageId,currentMessageId)
            draft[id]={[currentMessageId]: {
                ...content,
                children: [],
                parent: '',
                endpointName: endpointName
              }
            };
            
          }
        }),
      }));
    },
    editMessage: (id: string, messageId: string, content: string, references:ReferencesMeta) => {
      //console.log("useChat:editMessage",id,messageId,content);
      set((state) => ({
        chats: produce(state.chats, (draft: { [id: string]: { [messageId: string]: { content: { message: string, session_id:string, references:ReferencesMeta}; }; }; }) => {
          // console.log("useChat:editMessage:state.chats",state.chats, content, references);
          draft[id][messageId].content.message = content;
          draft[id][messageId].content.references = references;
        }),
      }));
    },

    
    removeMessage: (id: string, messageId: string) => {
      set((state) => ({
        
        chats: produce(state.chats, (draft: { [id: string]: { [messageId: string]: any; }; }) => {
          if (draft[id]){
            const childrenIds = [...draft[id][messageId].children];

            console.log("useChat:set",draft)
            while (childrenIds.length > 0) {
              const targetId = childrenIds.pop()!;
              childrenIds.push(...draft[id][targetId].children);
              delete draft[id][targetId];
            }

            Object.keys(draft[id]).forEach((key) => {
              const idx = draft[id][key].children.findIndex(
                (c: string) => c === messageId
              );
              if (idx > -1) {
                draft[id][key].children.splice(idx, 1);
              }
            });
            delete draft[id][messageId];
          }
        }),
      }));
    },
    getMessages: (id: string, currentMessageId: string) => {
      //console.log("useChat:getMessages",id,currentMessageId,get().chats,get(),get().chats[id]);
      //currentMessageId=NEW_MESSAGE_ID.ASSISTANT;
      return convertMessageMapToArray(get().chats[id] ?? {}, currentMessageId);
    },

    currentMessageId: '',
    setCurrentMessageId: (s: string) => {
      set(() => ({
        currentMessageId: s,
      }));
    },
    isGeneratedTitle: false,
    setIsGeneratedTitle: (b: boolean) => {
      set(() => ({
        isGeneratedTitle: b,
      }));
    },
    getPostedModelName: () => {
      return (
        get().chats[get().conversationId]?.system?.endpointName ??
        get().chats['']?.[NEW_MESSAGE_ID.ASSISTANT]?.endpointName
      );
    }
  };
});

const useChat = () => {
  const { t } = useTranslation();
  const {
    chats,
    conversationId,
    setConversationId,
    postingMessage,
    setPostingMessage,
    setMessages,
    pushMessage,
    editMessage,
    copyMessages,
    removeMessage,
    getMessages,
    currentMessageId,
    setCurrentMessageId,
    isGeneratedTitle,
    setIsGeneratedTitle,
    getPostedModelName,
  } = useChatState();
  const { open: openSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { post: postStreaming } = usePostMessageStreaming();
  const { uploadFile } = useUploadFile();
  const [isUploadInstatntly, setIsUploadInstatntly] = useState( window.localStorage.getItem('isUploadInstatntly')==='true');

  const conversationApi = useConversationApi();
  const {
    data,
    mutate,
    isLoading: loadingConversation,
    error,
  } = conversationApi.getConversation(conversationId);


  const { syncConversations } = useConversation();
  const { conversations } = useConversation();

  const messages = useMemo(() => {
    return getMessages(conversationId, currentMessageId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, chats, currentMessageId]);

  const newChat = useCallback(() => {
    setConversationId('');
    setMessages('', {});
  }, [setConversationId, setMessages]);

  useEffect(() => {
    if (error?.response?.status === 404) {
      openSnackbar(t('error.notFoundConversation'));
      navigate('');
      newChat();
    } else if (error) {
      openSnackbar(error?.message ?? '');
    }
  }, [error, navigate, newChat, openSnackbar, t]);

  // useEffect(() => {
  //   if (conversationId && data?.id === conversationId) {
  //     //setMessages(conversationId, data.messageMap); //DEBUG:
  //     //setCurrentMessageId(data.lastMessageId);
  //   }
  // }, [conversationId, data, setCurrentMessageId, setMessages]);

  useEffect(() => {
    if (conversationId && conversations) {
      const conv:ConversationMeta=conversations.filter((c) => c.id===conversationId)[0]
      if (conv)
        setCurrentMessageId(conv.lastMessageId);
      
  
      //setMessages(conversationId, data.messageMap); //DEBUG:
      //setCurrentMessageId(data.lastMessageId);
    }
  }, [conversationId, conversations, setCurrentMessageId, setMessages]);


  useEffect(() => {
    setIsGeneratedTitle(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  const pushNewMessage = (
    conversationId:string,
    parentMessageId: string | null,
    messageContent: MessageContent,
    endpointName:string,
    messageId:string,
    responseId:string
  ) => {
    console.log("useChat:pushNewMessage:",conversationId,parentMessageId,messageContent,endpointName);
    pushMessage(
      conversationId ?? '',
      parentMessageId,
      messageId, //NEW_MESSAGE_ID.USER,
      messageContent,
      endpointName
    );
    pushMessage(
      conversationId ?? '',
      messageId, //NEW_MESSAGE_ID.USER,
      responseId, //NEW_MESSAGE_ID.ASSISTANT,
      {
        role: 'assistant',
        endpointName:endpointName,
        content :{action: "SendMessage",
        //contentType: 'text',
        references:[],
        attachments:[],
        files:undefined,
        message: '',
        session_id: conversationId,
        parameters: {
        }}, // messageContent.content
        
      },
      endpointName
    );
  };

  const postChat = (content: string, endpoint: APITypes.Endpoint, files:File[],urls:string[]) => {
    const isNewChat = conversationId ? false : true;
    const newConversationId = 'C'+ulid();
    const messageId = 'M'+ulid();
    const responseId = 'R'+ulid();
    const parameters=window.localStorage.getItem('modelParameters')?JSON.parse(''+window.localStorage.getItem('modelParameters')):{
      temperature: 1,
      p_value: 0.7,
      k_value: 250,
      maximum_length: 2048,
      k: 1
    };

    const modelToPost = isNewChat ? endpoint.title : getPostedModelName();

    setConversationId(isNewChat?newConversationId:conversationId);

    if (isNewChat){
      const conversation:ConversationMeta = {
        id:newConversationId,
        title: endpoint.title+' '+newConversationId,
        createTime: Date.now(),
        lastMessageId: messageId,
        endpoint: endpoint
      }
      conversations?.push(conversation)
    }
    
    console.log("useChatState.getState().chats[conversationId]",conversationId,useChatState.getState().chats[conversationId],endpoint.title, getPostedModelName())
    const tmpMessages = convertMessageMapToArray(
      useChatState.getState().chats[conversationId] ?? {},
      currentMessageId
    );

    console.log("useChatState:tmpMessages",tmpMessages);

    const parentMessageId = (!isNewChat && tmpMessages && tmpMessages.length>0)
      ? tmpMessages[tmpMessages.length - 1].id
      : newConversationId;
    
    //let urls:string[]=[];
    Promise.all(
      !isUploadInstatntly?files.map(async (file,index)=>{
        console.log("useChat:files:",index,file.name,);

        await uploadFile(endpoint.url,isNewChat?newConversationId:conversationId,file,(progress)=>{
          console.log("useChat:uploadFile:progress:",index,file.name,progress);

        },(url)=>{
          urls= [...urls,url];
          //urls.push(url);
          console.log("useChat:uploadFile:url:",index,file.name,url,urls);
        })
      }):[]
      ).then(()=>{

          console.log("Promise.all:urls:",urls);

          const messageContent: MessageContent = {
            content: {
              action: "SendMessage",
              //contentType: 'text',
              message: content,
              references: [],
              session_id: isNewChat?newConversationId:conversationId,
              parameters: parameters,
              attachments:urls,
              files:files,
            },
            endpointName: modelToPost,
            role: 'user',
          };
          const input: PostMessageRequest = {
            wssApiUrl:endpoint.url,
            conversationId: isNewChat ? newConversationId : conversationId,
            message: {
              ...messageContent,
              parentMessageId: parentMessageId,
              endpointName: modelToPost
            },
            
            stream: true,
          };
          const createNewConversation = () => {
            copyMessages('', newConversationId);
      
            conversationApi
              .updateTitleWithGeneratedTitle(newConversationId)
              .finally(() => {
                syncConversations().then(() => {
                  setIsGeneratedTitle(true);
                //setConversationId(newConversationId);
          
                });
              });
          };
      
          setPostingMessage(true);
      
          console.log("useChat:createNewConversation",parentMessageId, messageContent,modelToPost)
          //pushNewMessage(parentMessageId, messageContent, modelToPost,messageId,responseId);
          pushNewMessage(isNewChat?newConversationId:conversationId,parentMessageId, messageContent, modelToPost,messageId,responseId);
      
          setCurrentMessageId(responseId);

        console.log("PostChat:input.message.content.attachments:",input.message.content.attachments);

        const postPromise: Promise<void> = new Promise((resolve, reject) => {
          if (USE_STREAMING) {
            postStreaming(input, (c: string,s: string, r:ReferencesMeta, e:string|null) => {
              editMessage(isNewChat?newConversationId:conversationId,responseId , c, r); //NEW_MESSAGE_ID.ASSISTANT
              if (e){
                openSnackbar(e);
              }
              //editMessageReferences(isNewChat?newConversationId:conversationId,responseId , r);
            })
              .then(() => {
                resolve();
              })
              .catch((e) => {
                reject(e);
              });
          } else {
            conversationApi
              .postMessage(input)
              .then((res) => {
                editMessage(
                  conversationId ?? '',
                  NEW_MESSAGE_ID.ASSISTANT,
                  res.data.message.content.message,
                  []
                );
                resolve();
              })
              .catch((e) => {
                reject(e);
              });
          }
        });
    
        postPromise
          .then(() => {
            if (isNewChat) {
              console.log('useChat:postPromise:createNewConversation')
              //createNewConversation(); //DEBUG:
            } else {
              mutate();
            }
          })
          .catch((e) => {
            console.error(e);
            //removeMessage(conversationId ?? '', NEW_MESSAGE_ID.ASSISTANT);
          })
          .finally(() => {
            setPostingMessage(false);
            //copyMessages('', isNewChat?newConversationId:conversationId);
            //setConversationId(isNewChat?newConversationId:conversationId);
            setCurrentMessageId(messageId);
          });
      })
  };

  /**
   *
   * @param props content: content  messageId: messageId
   */
  const regenerate = (props?: { content?: string; messageId?: string, endpoint?: APITypes.Endpoint }) => {
    let index: number = -1;
    // messageId
    if (props?.messageId) {
      index = messages.findIndex((m) => m.id === props.messageId);
    }

    
    const isRetryError = messages[messages.length - 1].role === 'user';

    if (index === -1) {
      index = isRetryError ? messages.length - 1 : messages.length - 2;
    }

    const parentMessage = produce(messages[index], (draft: { content: { message: string; }; }) => {
      if (props?.content) {
        draft.content.message = props.content;
      }
    });

    // State
    if (props?.content) {
      editMessage(conversationId, parentMessage.id, props.content, []); //TODO: DEBUG props?references

    }

    const input: PostMessageRequest = {
      wssApiUrl: props?.endpoint?.url?props?.endpoint?.url:'', //DEBUG: TODO:
      conversationId: conversationId,
      message: {
        ...parentMessage,
        parentMessageId: parentMessage.parent,
      },
      stream: true,
    };

    setPostingMessage(true);

    if (isRetryError) {
      pushMessage(
        conversationId ?? '',
        parentMessage.id,
        NEW_MESSAGE_ID.ASSISTANT,
        {
          role: 'assistant',
          content: messages[index].content,

          endpointName: messages[index].endpointName,

        },
        messages[index].endpointName
      );
    } else {
      pushNewMessage(conversationId ?? '',parentMessage.parent, parentMessage, messages[index].endpointName,NEW_MESSAGE_ID.USER,NEW_MESSAGE_ID.ASSISTANT);
    }

    //setCurrentMessageId(NEW_MESSAGE_ID.ASSISTANT);
    console.log("------------")

    postStreaming(input, (c: string, s:string, r:ReferencesMeta) => {
      editMessage(conversationId, NEW_MESSAGE_ID.ASSISTANT, c, r);
    })
      .then(() => {
        mutate();
      })
      .catch((e) => {
        console.error(e);
        setCurrentMessageId(NEW_MESSAGE_ID.USER);
        removeMessage(conversationId, NEW_MESSAGE_ID.ASSISTANT);
      })
      .finally(() => {
        setPostingMessage(false);
      });
  };

  const hasError = useMemo(() => {
    const length_ = messages.length;
    return length_ === 0 ? false : messages[length_ - 1].role === 'user';
  }, [messages]);

  return {
    hasError,
    setConversationId,
    conversationId,
    loadingConversation,
    postingMessage: postingMessage || loadingConversation,
    isGeneratedTitle,
    setIsGeneratedTitle,
    newChat,
    messages,
    setCurrentMessageId,
    postChat,
    regenerate,
    getPostedModelName,

    retryPostChat: (content?: string, endpoint?: APITypes.Endpoint) => {
      const length_ = messages.length;
      if (length_ === 0) {
        return;
      }
      const latestMessage = messages[length_ - 1];
      if (latestMessage.sibling.length === 1) {
        removeMessage(conversationId, latestMessage.id);
        //postChat(content ?? latestMessage.content.message, getPostedModelName());
        endpoint?postChat(content ?? latestMessage.content.message, endpoint,[],[]):''; //DEBUG: TODO:
      } else {
        regenerate({
          content: content ?? latestMessage.content.message,
        });
      }
    },
  };
};

export default useChat;
