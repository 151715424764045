const translation = {
  translation: {
    app: {
      name: 'Chaos Gears - Generative AI',
      inputMessage: 'Wyślij wiadomość',
    },
    deleteDialog: {
      title: 'Usunąć?',
      content: 'Czy na pewno chcesz usunąć <Bold>{{title}}</Bold>?',
    },
    clearDialog: {
      title: 'Delete ALL?',
      content: 'Czy na pewno chcesz usunąć wszystkie konwersacje?',
    },
    modelDialog: {
      title: 'Model Parameters',
      endpointName: 'Endpoint Name',
      temperature: 'temperature',
      p_value: 'p_value',
      k_value: 'k_value',
      maximum_length: "maximum_length",
      k: 'k'
    },
    languageDialog: {
      title: 'Zmień język',
    },
    button: {
      newChat: 'Nowa konwersacja',
      SaveAndSubmit: 'Zapisz i Wyślij',
      resend: 'Ponów',
      regenerate: 'Zregeneruj',
      delete: 'Usuń',
      deleteAll: 'Usuń wszystkie',
      ok: 'OK',
      cancel: 'Anuluj',
      menu: 'Menu',
      language: 'Języki',
      clearConversation: 'Usuń wszystkie konwersacje',
      signOut: 'Sign out',
      showReferences: 'Pokazuj/Ukrywaj referencje',
      uploadInstantly: 'Wyślij obrazy natychmiast',
      modelParameters: 'Parametry Modelu',
      Save: 'Zapisz',
      SetDefaults: 'Domyślne wartości',
    },
    error: {
      answerResponse: 'Wystąpił błąd podczas pobierania odpowiedzi!',
      notFoundConversation:
        'Ta konwersacja już nie istnieje. Zostanie rozpoczęta nowa.',
      notFoundPage: 'Strona którą poszukujesz, nie istnieje!',
      predict: {
        general: 'Wystąpił błąd',
        invalidResponse:
          'Wystąpił nieoczekiwany format odpowiedzi.',
      },
    },
  },
};

export default translation;
