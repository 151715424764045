import { FaFileAlt,FaLink,FaDragon,FaDatabase } from 'react-icons/fa';
import { AiFillThunderbolt } from 'react-icons/ai';
import { BaseProps } from '../@types/common';
import Button from './Button';
//import { ReferencesMeta, ReferenceMeta } from '../@types/conversation';

type Props = BaseProps & {
  files:File[]| undefined;
  // progress:number[],
openUrl:(url: string) => void;
};



const Thumbnails: React.FC<Props> = (props) => {

  
  return(
    <div className=''>
      <div className={`grid grid-cols-4 items-left justify-left`}>
            {props.files?.map((file:File, index)=>{
                return <img src={URL.createObjectURL(file)} key={file.name} title={`${file.name} [${file.size}]`} className={`object-scale-down w-64 max-h-72 max-w-72  m-1 `}/> //opacity-${50+Math.ceil(props.progress[index]/20)*10} <FaFileImage key={file.name} title={`${file.name} [${file.size}]`} className="h-14 w-7 ml-1"/>
              })}
            </div>
    </div>
  );
};

export default Thumbnails;
