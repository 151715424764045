import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
  } from 'react';
  import ReactDOM from 'react-dom'
  import { BaseProps } from '../@types/common';
  import { Link, useNavigate } from 'react-router-dom';
  import useInfoDrawer from '../hooks/useInfoDrawer';
  import ButtonIcon from './ButtonIcon';
  import {
    PiX,PiChat,
    PiCheck,
    PiPencilLine,
  } from 'react-icons/pi';
  
  import Button from './Button';
  import useConversation from '../hooks/useConversation';
  import LazyOutputText from './LazyOutputText';
  import { ConversationMeta } from '../@types/conversation';
  import { isMobile } from 'react-device-detect';
  import useChat from '../hooks/useChat';
  import { useTranslation } from 'react-i18next';
  import Menu from './Menu';

  import useEndpoint from '../hooks/useEndpoint';
  import Markdown from 'react-markdown'
  import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';


  type Props = BaseProps;
  
  


  const InfoDrawer: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { opened, switchOpen, covered, switchCovered} = useInfoDrawer();

    const {endpoint} = useEndpoint();
  
  
    const smallInfoDrawer = useRef<HTMLDivElement>(null);
  
    const closeSamllDrawer = useCallback(() => {
      if (smallInfoDrawer.current?.classList.contains('visible')) {
        switchOpen();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    useLayoutEffect(() => {

      const onResize = () => {
        if (isMobile) {
          return;
        }
  

        if (!smallInfoDrawer.current && opened ) {
            switchOpen();
        }

      };
      onResize();
  
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opened,covered]);
  
    return (
      <>
        <div className={`${(opened||!covered) ? 'visible w-64' : 'invisible w-12'} relative ${!covered||opened?'h-full':'h-10'} overflow-y-auto bg-aws-squid-ink `}>
          <nav
            className={`lg:visible lg:w-64 ${
              (opened||!covered ) ? 'visible w-64' : 'invisible w-0'
            } text-sm  text-white transition-width`}>
                
            <div
              className={`${
                (opened||!covered ) ? 'w-64' : 'w-0'
              } fixed top-0 z-50 h-14 bg-aws-squid-ink p-2 transition-width lg:w-64 `}>
              {!opened&&<ButtonIcon 
                className="mr-2 rounded-full p-2 hover:brightness-50 focus:outline-none focus:ring-1 "
                onClick={switchCovered}>
                <PiChat />
            </ButtonIcon>
            }
            </div>

  
            <div className={` absolute top-14 w-full overflow-y-auto overflow-x-hidden pb-12 p-4 `}>
            {/* {opened?'opened:true':'opened:false'} {covered?'covered:true':'covered:false'} */}
              <Markdown
                className="prose lg:prose-sm prose-invert"
                remarkPlugins={[remarkGfm, remarkBreaks]}
                children={endpoint?.description}
                
                components={{
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                   
                }}
                />
            </div>
          </nav>
        </div>
  
        <div
          ref={smallInfoDrawer}
          className={`lg:hidden ${(opened )? 'visible' : 'hidden'}`}>
          <ButtonIcon
            className="fixed right-2 top-2 z-50 text-white"
            onClick={switchOpen}>
            <PiX />
          </ButtonIcon>
          <div
            className="fixed z-40 h-screen w-screen bg-gray-900/90"
            onClick={switchOpen}></div>
        </div>
      </>
    );
  };
  
  export default InfoDrawer;
  