import React, { useCallback, useState } from 'react';
import ButtonIcon from './ButtonIcon';
import { BaseProps } from '../@types/common';
import { PiBookBookmark , PiBookBookmarkFill } from 'react-icons/pi';

type Props = BaseProps & {
  opened: boolean,
  setOpened(opened:boolean):void
};

const ButtonReferences: React.FC<Props> = (props) => {
  const [showsCheck, setshowsCheck] = useState(false);

  return (
    <ButtonIcon
      className={`${props.className ?? ''}`}
      onClick={() => {
        props.setOpened(!props.opened);
      }}>
      {props.opened ? <PiBookBookmark /> : <PiBookBookmarkFill />}
    </ButtonIcon>
  );
};

export default ButtonReferences;
