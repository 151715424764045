

import React from "react";
import { FaPaperclip } from 'react-icons/fa';
import { BaseProps } from "../@types/common";

type Props = BaseProps & {
  disabled?: boolean;
  onClick: () => void;
};

const ButtonAttach: React.FC<Props> = (props) => {
  return (
    <button
      className={`${
        props.className ?? ""
      } flex items-center justify-center rounded-xl mt-2 ml-1 p-2 text-xl text-aws-sea-blue  ${
        props.disabled ? "opacity-30" : ""
      }`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <FaPaperclip />
    </button>
  );
};

export default ButtonAttach;