import { create } from "zustand";
import { Endpoint } from "../API";

const useEndpointState = create<{
  endpoint: Endpoint;
  setEndpoint: (endpoint:Endpoint|undefined) => void;
}>((set) => {
  return {
    endpoint: {__typename: "Endpoint",
    id: '',
    title: '',
    url: '',
    status: '' ,
    canUploadFile: '', 
    cognitoGroups: [],
    createdAt: '',
    updatedAt: '',
    description: ''},
    setEndpoint: (endpoint) => {
      set((state) => ({
        endpoint: endpoint,
      }));
    },
  };
});

const useEndpoint = () => {
  const [endpoint, setEndpoint] = useEndpointState((state) => [
    state.endpoint,
    state.setEndpoint,
  ]);

  return {
    endpoint,
    setEndpoint,
  };
};

export default useEndpoint;