import { create } from "zustand";

const useInfoDrawerState = create<{
  opened: boolean;
  switchOpen: () => void;
  covered: boolean;
  switchCovered: () => void;
}>((set) => {
  return {
    opened: false,
    switchOpen: () => {
      set((state) => ({
        opened: !state.opened,
      }));
    },
    covered: false,
    switchCovered: () => {
      set((state) => ({
        covered: !state.covered,
      }));
    },
  };
});

const useInfoDrawer = () => {
  const [opened, switchOpen, covered, switchCovered] = useInfoDrawerState((state) => [
    state.opened,
    state.switchOpen,
    state.covered,
    state.switchCovered
  ]);

  return {
    opened,
    switchOpen,
    covered,
    switchCovered,
  };
};

export default useInfoDrawer;