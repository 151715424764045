import { fetchAuthSession } from 'aws-amplify/auth';
import { PostMessageRequest,ReferenceMeta,ReferencesMeta } from '../@types/conversation';
import { create } from 'zustand';
import i18next from 'i18next';
//import { useAuthenticator } from '@aws-amplify/ui-react'


const usePostMessageStreaming = create<{
  post: (
    input: PostMessageRequest,
    dispatch: (completion: string, session_id: string, references:ReferencesMeta, error:string|null) => void
  ) => Promise<void>;
}>(() => {
  const post = async (
    input: PostMessageRequest,
    dispatch: (completion: string, session_id:string, references:ReferencesMeta, error:string|null) => void
  ) => {
    const token = (await fetchAuthSession()); //TODO: move to use
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    console.log('usePostMessageStreaming',token,idToken);

    return new Promise<void>((resolve, reject) => {

      const ws = new WebSocket(input.wssApiUrl,["websocket", ''+token.tokens?.idToken]);
      
      let completion = '';
      let references:ReferencesMeta =[];
      references=[];

      //const {user } = useAuthenticator(context => [context.user]); //TODO: check if is it possible to get the token using use

      ws.onopen = () => {
        ws.send(JSON.stringify({ ...input.message.content }));
      };

      ws.onmessage = (message) => {
        try {
          if (message.data === '') {
            return;
          }

          const data = JSON.parse(message.data);
          // console.log("usePostMessageStreaming:data",data);

          if (data.type && data.type==='processing'){
            console.log("usePostMessageStreaming:processing",data.session_id);


          } else if (data.type && data.type==='typing'){
            console.log("usePostMessageStreaming:typing",data.session_id);


          }else if (data.message){
            console.log("------------usePostMessageStreaming:message:",data.session_id,data.message);
            //TODO: do nothing

          } else if (data.type && data.type==='token' && (data.text || data.text === '')) { //and "type":"token"
            // if (completion.endsWith('▍')) {
            //   completion = completion.slice(0, -1);
            // }

            completion += data.text; //TODO: DEBUG:
            dispatch(completion,data.session_id,references,null);
            
          } else if (data.type && data.type==='references' && (data.references)) {
            console.log("usePostMessageStreaming:data:references:",data.references)

          } else if (data.type && data.type==='reference' && (data.references)) {
            // console.log("usePostMessageStreaming:data:reference:",data.references)
            //{"type": "reference", "session_id":"<SESSION_ID>", "references": [ {"text": "<SOURCE_TEXT>", "metadata": {"url": "<PRESIGNED_URL>", "resource_name": "<RESOURCE_NAME>"} } ]}
            references =data.references; //TODO: Add multi references? Probably not necessary 
            dispatch(completion,data.session_id,data.references,null);

          } else if (data.type && data.type==='completed') {
            console.log("usePostMessageStreaming:data:completed")
            ws.close();
          } else {
            console.error('error.predict.invalidResponse',data);
            dispatch(completion,data.session_id,references,'error.predict.invalidResponse:');
            ws.close();         
            //throw new Error(i18next.t('error.predict.invalidResponse')); //TODO: DEBUG: add 'Endpoint request timed out'
          }
        } catch (e) {
          console.error(e);
          reject(i18next.t('error.predict.general'));
        }
      };

      ws.onerror = (e) => {
        ws.close();
        console.error(e);
        reject(i18next.t('error.predict.general'));
      };
      ws.onclose = () => {
        resolve();
      };
    });
  };

  return {
    post,
  };
});
export default usePostMessageStreaming;
