import React from 'react';
import { useState } from 'react';
import { BaseProps } from '../@types/common';
import Button from './Button';
import ModalDialog from './ModalDialog';
import { Trans, useTranslation } from 'react-i18next';



type Props = BaseProps & {
  isOpen: boolean;
  onClose: () => void;
};

const DialogModelParameters: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [endpointName, setEndpointName] = useState('');
  const [parameters, setParameters] = useState(window.localStorage.getItem('modelParameters')?JSON.parse(''+window.localStorage.getItem('modelParameters')):{
    temperature: 1,
    p_value: 0.7,
    k_value: 250,
    maximum_length: 2048,
    k: 1
  }); //TODO: local storage or default JSON.parse(window.localStorage.getItem('modelParameters')

    function onSave() {
        window.localStorage.setItem('modelParameters', JSON.stringify(parameters));

        props.onClose();
    }

    function onSetDefault() {
        setParameters({
            temperature: 1,
            p_value: 0.7,
            k_value: 250,
            maximum_length: 2048,
            k: 1
          })
    }

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setParameters({...parameters,[name]:value})

      };
  return (
    <ModalDialog {...props} title={t('modelDialog.title')}>
      {/* <div>
            <Trans
            i18nKey="modelDialog.endpointName"
            components={{
                Bold: <span className="font-bold" />,
            }}
            />
        </div> */}
        <div>
            <Trans
            i18nKey="modelDialog.temperature"
            components={{
                Bold: <span className="font-bold" />,
            }}
            /> :<input className='m-2 -mr-14 bg-silver pr-14' type="text" name='temperature' pattern="[0-9]*"
            onChange={handleChange} value={parameters.temperature} />
        </div>


        <div>
            <Trans
            i18nKey="modelDialog.p_value"
            components={{
                Bold: <span className="font-bold" />,
            }}
            />
        :<input className='m-2 -mr-14 bg-silver pr-14' type="text" name='p_value' pattern="[0-9]*"
            onChange={handleChange} value={parameters.p_value} />
        </div>

        <div>
            <Trans
            i18nKey="modelDialog.k_value"
            components={{
                Bold: <span className="font-bold" />,
            }}
            />:<input className='m-2 -mr-14 bg-silver pr-14' type="text" name='k_value' pattern="[0-9]*"
            onChange={handleChange} value={parameters.k_value} />
        </div>

        <div>
            <Trans
            i18nKey="modelDialog.maximum_length"
            components={{
                Bold: <span className="font-bold" />,
            }}
            />:<input className='m-2 -mr-14 bg-silver pr-14' type="text" name='maximum_length' pattern="[0-9]*"
            onChange={handleChange} value={parameters.maximum_length} />
        </div>

        <div>
            <Trans
            i18nKey="modelDialog.k"
            components={{
                Bold: <span className="font-bold" />,
            }}
            />:<input className='m-2 -mr-14 bg-silver pr-14' type="text" name='k' pattern="[0-9]*"
            onChange={handleChange} value={parameters.k} />
        </div>

        
      {/* {JSON.stringify(parameters)} */}

      <div className="mt-4 flex justify-end gap-2">
        <Button
          onClick={props.onClose}
          className="bg-transparent p-2 text-aws-font-color">
          {t('button.cancel')}
        </Button>

        <Button
          onClick={() => {
            onSetDefault();
          }}
          className="bg-transparent p-2 text-aws-font-color">
          {t('button.SetDefaults')}
        </Button>

        <Button
          onClick={() => {
            onSave();
          }}
          className="bg-red-500 p-2 text-aws-font-color-white">
          {t('button.Save')}
        </Button>
      </div>
    </ModalDialog>
  );
};

export default DialogModelParameters;
