import { FaBrain,FaBattleNet  } from 'react-icons/fa';
import { AiFillThunderbolt } from 'react-icons/ai';
import { BaseProps } from '../@types/common';
//import { Model } from '../@types/conversation';
import Button from './Button';
import * as APITypes from "../API";

type Props = BaseProps & {
  //postedModel: Model | null;
  endpoint: APITypes.Endpoint | undefined;
  setEndpoint: (endpoint: APITypes.Endpoint) => void;
  endpoints:Array<APITypes.Endpoint> | undefined;
  groups:string[] | undefined;
};

const SwitchBedrockModel: React.FC<Props> = (props) => {
  const buttonClass =
    'flex-1 min-w-40 flex items-center rounded-lg p-2 justify-center whitespace-nowrap';
  // return props.endpoint ? (
  //   <div className="text-sm text-gray-500">model: {props.endpoint.title}</div>
  // ) :  //DEBUG: po wybraniu modelu nie ma zmiany, albo konwersacji
  const imgClass='';
  
  return(
    <div>
      <div className="flex justify-center gap-2 rounded-lg border bg-gray-200 p-1 text-sm">

      {props.endpoints?.map((endpoint:APITypes.Endpoint) =>{
            return (
              (!props.groups?.some(r=>endpoint.cognitoGroups.includes(r)))&&!props.groups?.includes('admins')?'':
              <Button
                className={`${buttonClass} ${endpoint.canUploadFile=='true'?'underline':''} ${
                  props.endpoint && (props.endpoint.title == endpoint.title)
                    ? 'bg-aws-squid-ink/100 text-white'
                    : 'bg-aws-paper text-gray-500'
                }`}
                // rightIcon={endpoint.images=='true'?<FaBrain />:<FaBattleNet  />}
                icon={props.groups?.includes(endpoint.title.replace(' ',''))?<FaBrain />:<FaBattleNet  />}
                disabled={(!props.groups?.some(r=>endpoint.cognitoGroups.includes(r)))&&!props.groups?.includes('admins')}
                title={endpoint.url}
                key={endpoint.title}
                onClick={() => props.setEndpoint(endpoint)}
                children={<span>{endpoint.title}</span>}></Button>
            )
          })}
      </div>
    </div>
  );
};

export default SwitchBedrockModel;
