import React, { useCallback, useEffect, useState, ChangeEvent} from 'react';
import InputChatContent from '../components/InputChatContent';
import InputChatFileContent from '../components/InputChatFileContent';
import useChat from '../hooks/useChat';
import ChatMessage from '../components/ChatMessage';
import useScroll from '../hooks/useScroll';
import { useParams } from 'react-router-dom';
import { PiArrowsCounterClockwise, PiWarningCircleFill } from 'react-icons/pi';
import Button from '../components/Button';
import { useTranslation } from 'react-i18next';
import SwitchBedrockModel from '../components/SwitchBedrockModel';
//import { ModelDefinition } from '../@types/conversation';
//import {GraphQLAPI} from '@aws-amplify/api-graphql';
//import API, {GraphQLResult} 
//  from '@aws-amplify/api';
//import { GraphQLAuthMode, DocumentType } from '@aws-amplify/core/internals/utils'; userPool
import * as APITypes  from "../API";
import {Endpoint}  from "../API";
//import {listEndpoints} from '../configs/queries';
//import { generateClient } from 'aws-amplify/api';
import { options } from 'prettier-plugin-tailwindcss';
import useConversationApi from '../hooks/useConversationApi';
import useUseCaseApi from '../hooks/useUseCaseApi';
import useUploadFile from '../hooks/useUploadFile';
import { MessageContentWithChildren } from '../@types/conversation';
import { useCookies } from "react-cookie";
import { fetchAuthSession,getCurrentUser } from 'aws-amplify/auth';
import SnackbarProvider from '../providers/SnackbarProvider';
import { ulid } from 'ulid';
import useEndpoint from '../hooks/useEndpoint';


const ChatPage: React.FC = () => {
  const { t } = useTranslation();
  const [content, setContent] = useState('');

  const {
    postingMessage,
    postChat,
    messages,
    setConversationId,
    hasError,
    retryPostChat,
    setCurrentMessageId,
    regenerate,
    conversationId
  } = useChat();
  const { scrollToBottom, scrollToTop } = useScroll();

  const { conversationId: paramConversationId } = useParams();

  const [endpoints, setEndpoints] = useState<Array<APITypes.Endpoint>>();
  //const [endpoint, setEndpoint] = useState<APITypes.Endpoint>();

  // const {endpoint, setEndpoint} = useEndpoint();


  const [cookies, setCookie] = useCookies(["lastEndpoint"]);
  const [groups, setGroups] = useState<any >();
  const [status, setStatus] = useState<string>();
  //const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [urls, setUrls] = useState<string[]>([]);
  const [progress, setProgress] = useState<number[]>([]);
  const [inputStatus, setInputStatus] = useState<string>("idle");

  const useCaseApi= useUseCaseApi();
  const { uploadFile } = useUploadFile();
  
  const [isUploadInstatntly, setIsUploadInstatntly] = useState( window.localStorage.getItem('isUploadInstatntly')==='true');

  const {
    data,
    error,
  } = useCaseApi.getEndpoints();

  const {endpoint, setEndpoint} = useEndpoint();
  

  const changeEndpoint = (endpoint:Endpoint) => {
    setEndpoint(endpoint);
    setCookie('lastEndpoint', endpoint.title,{ path: "/" });
    setConversationId('');
    setCurrentMessageId('');
    setFiles([]);
  }

  const handleFilesChange = (files: File[]) => {
    console.log("ChatPage:handleFilesChange:",files, isUploadInstatntly,paramConversationId,conversationId)
    setFiles(files);
    setProgress(files.map(v=>(0)));
    if (isUploadInstatntly && endpoint && endpoint.url){
      let newConversationId = 'C'+ulid();

      if (!paramConversationId && !conversationId){
        setConversationId(newConversationId);
      }else{
        newConversationId=conversationId;
      }
      
      //let urls:string[]=[];

      files.map(async (file,index)=>{
        console.log("useChat:files:handleFilesChange",index,file.name,);

        await uploadFile(endpoint.url,newConversationId,file, (pr)=>{
          console.log("chatPage:uploadFile:progress:",index,file.name,pr);
          const prg=progress;
          prg[index]=pr;
          setProgress(prg);
          
        },(url)=>{
          setUrls([...urls,url]); //TODO: czy asynchroniczność nie popsuje tablicy
          console.log("chatPage:uploadFile:url:",index,file.name,url,urls);
        })
      })
    }
    //const file = files[0];
    //setSelectedFile(file || null);
  };
 

  const getGroupsAsync = async () => {
    fetchAuthSession().then(authSession=>{
    setGroups(authSession.tokens?.idToken?.payload['cognito:groups']);
    //console.log('------------groups',groups)
  }).catch(reason=>{
    console.error("Cognito fetch session error")
  });
}

// useEffect(() => {
//   if (selectedFile) {
//     if (selectedFile.type === "application/pdf") {
//       setInputStatus("valid");
//     } else {
//       setSelectedFile(null);
//     }
//   }
// }, [selectedFile]);

  useEffect(() => {
    if (data) {
      console.log("ChatPage:data:endpoints",data);
      setEndpoints(data);
      getGroupsAsync();

      if (data.length>0){
        console.log('------setEndpoint:cookies.lastEndpoint,groups',cookies.lastEndpoint)
        if (cookies.lastEndpoint){
          const lastEndpoint=data?.find((c) => c.title == cookies.lastEndpoint);
          console.log("--------lastEndpoint",lastEndpoint);
          lastEndpoint?setEndpoint(lastEndpoint):setEndpoint(data[0]);
        }else{
          changeEndpoint(data[0]);
        }
        
      }

    }
  }, [data,cookies, setEndpoint]);


  useEffect(() => {
    //console.log("chatPage:useEffect:paramConversationId",paramConversationId,conversationId);
    setConversationId(paramConversationId ?? conversationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramConversationId]);

  const onSend = useCallback(() => {
    console.log("ChatPage:onSend:endpoint",endpoint,files);
    if(endpoint){

      postChat(content, endpoint, files,urls);
      setContent('');
      setFiles([]);
    }
  }, [content, postChat]);

  const onChangeCurrentMessageId = useCallback(
    (messageId: string) => {
      console.log("ChatPage:onChangeCurrentMessageId:messageId",messageId);
      setCurrentMessageId(messageId);
    },
    [setCurrentMessageId]
  );

  const onSubmitEditedContent = useCallback(
    (messageId: string, content: string) => {
      if (hasError) {
        retryPostChat(content,endpoint);
      } else {
        regenerate({ messageId, content, endpoint});
      }
    },
    [hasError, regenerate, retryPostChat]
  );

  const onRegenerate = useCallback(() => {
    regenerate();
  }, [regenerate]);


  useEffect(() => {
    if (messages.length > 0) {
      if (messages[0].endpointName!=endpoint?.title){
        console.log("ChangeEndpoint:",messages[0].endpointName,endpoint?.title)
        setEndpoint(endpoints?.filter((c) => c.title===messages[0].endpointName)[0])
        //setCookie('lastEndpoint', endpoint.title,{ path: "/" }); //TODO:
      }
      scrollToBottom();
    } else {
      scrollToTop();
    }
  }, [messages, scrollToBottom, scrollToTop]);

  return (
    <><SnackbarProvider>
      <div className="flex flex-col items-center justify-start">
        

        
        <div className="m-1">
          <SwitchBedrockModel
            //postedModelName={getPostedModelName()}
            endpoint={endpoint}
            setEndpoint={changeEndpoint}
            endpoints={endpoints}
            groups={groups}
          />
        </div>
        <hr className="w-full border-t border-gray-300" />
      </div>
      {/* <div>
      paramConversationId:{paramConversationId} endpoint:{endpoint?.title} ConversationId:{conversationId} {messages.length+' '+paramConversationId}
      </div> */}
      <div className="pb-52 lg:pb-40">
        {messages.length === 0 ? (
          <>
            <div className="mx-3 my-32 flex items-center justify-center text-4xl font-bold text-gray-500/20">
              {t('app.name')}
            </div>
          </>
        ) : (
          messages.map((message: MessageContentWithChildren, idx: React.Key | null | undefined) =>
            message.content.message !== '' ? (
              <div
                key={idx}
                className={`${
                  message.role === 'assistant' ? 'bg-aws-squid-ink/5' : ''
                }`}>
                <ChatMessage
                  chatContent={message}
                  onChangeMessageId={onChangeCurrentMessageId}
                  onSubmit={onSubmitEditedContent}
                />
                <div className="w-full border-b border-aws-squid-ink/10"></div>
              </div>
            ) : (
              <ChatMessage key={idx} loading />
            )
          )
        )}
        {hasError && (
          <div className="mb-12 mt-2 flex flex-col items-center">
            <div className="flex items-center font-bold text-red-500">
              <PiWarningCircleFill className="mr-1 text-2xl" />
              {t('error.answerResponse')}
            </div>
            <Button
              className="mt-2 border-gray-400 bg-white shadow "
              icon={<PiArrowsCounterClockwise />}
              onClick={retryPostChat}>
              {t('button.resend')}
            </Button>

          </div>
        )}
      </div>
     

      <div>
        
      </div>

      <div className="absolute bottom-0 z-0 flex w-full justify-center">
      {endpoint?.canUploadFile=='true'? //DEBUG:
          <InputChatFileContent
          content={content}
          disabled={postingMessage}
          onChangeContent={setContent}
          onSend={onSend}
          onRegenerate={onRegenerate}
          handleFilesChange={handleFilesChange}
          files={files}
          progress={progress}
          isUploadInstatntly={isUploadInstatntly}
        />
        :
        <InputChatContent
              content={content}
              disabled={postingMessage}
              onChangeContent={setContent}
              onSend={onSend}
              onRegenerate={onRegenerate}
            />
      }
        
      </div>
      </SnackbarProvider>
    </>
  );
};

export default ChatPage;
