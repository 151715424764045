import { MutatorCallback, useSWRConfig } from 'swr';

import useHttp from './useHttp';
import {Endpoint}  from "../API";

const useUseCaseApi = () => {
  const http = useHttp();

  

  return {
    getEndpoints: () => {
      //console.log("useUseCaseApi:getEndpoints")
      return http.get<Endpoint[]>(
        `endpoints`,
        {
          keepPreviousData: false,
        }
      );
      }

  };
};

export default useUseCaseApi;
