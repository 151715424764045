import React, { useEffect, useMemo, ChangeEvent, DragEvent, useState,useRef   } from 'react';
import ButtonSend from './ButtonSend';
import ButtonAttach from './ButtonAttach';
import Textarea from './Textarea';
import useChat from '../hooks/useChat';
import Button from './Button';
import { PiArrowsCounterClockwise } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import { FaPaperclip } from 'react-icons/fa';

type Props = {
  content: string;
  disabled?: boolean;
  placeholder?: string;
  onChangeContent: (content: string) => void;
  onSend: () => void;
  onRegenerate: () => void;
  handleFilesChange: (files:File[]) => void;
  files:File[];
  progress:number[];
  isUploadInstatntly:boolean;
};

const InputChatFileContent: React.FC<Props> = (props) => {
const [isOver, setIsOver] = useState(false);
  //const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const { postingMessage, hasError, messages } = useChat();

  const disabledSend = useMemo(() => {
    return props.content === '' || props.disabled || hasError;
  }, [hasError, props.content, props.disabled]);

  const disabledRegenerate = useMemo(() => {
    return postingMessage || hasError;
  }, [hasError, postingMessage]);

  useEffect(() => {
    const listener = (e: DocumentEventMap['keypress']) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();

        if (!disabledSend) {
          props.onSend();
        }
      }
    };
    document
      .getElementById('input-chat-content')
      ?.addEventListener('keypress', listener);

    return () => {
      document
        .getElementById('input-chat-content')
        ?.removeEventListener('keypress', listener);
    };
  });

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(true);
  };
 
  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
  };
 
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
 
    // Fetch the files
    const validFiles:File[] = [];
    const droppedFiles = Array.from(event.dataTransfer.files);
    //setFiles(droppedFiles);
    //props.handleFilesChange(droppedFiles);
    console.log("handleDrop",droppedFiles);
    droppedFiles.forEach((file) => {
      console.log("Dropped file type: ",file.type);
      if (file.type.startsWith('image/')){
        validFiles.push(file);
      } else {
        console.error("File validation error:",file.name,file.type, ' is not valid image format!')
      }
    })
    props.handleFilesChange(validFiles);
 
    // Use FileReader to read file content
    // droppedFiles.forEach((file) => {
    //   const reader = new FileReader();
 
    //   reader.onloadend = () => {
    //     console.log(reader.result);
    //   };
 
    //   reader.onerror = () => {
    //     console.error("There was an issue reading the file.");
    //   };
 
    //   reader.readAsDataURL(file);
    //   return reader;
    // });
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const files:File[] = Array.from(e.currentTarget.files?e.currentTarget.files:[]);
    if (files)
      props.handleFilesChange(files)
    }

  return (
    <div
      id="input-chat-content"
      className={`relative mb-7 w-11/12 items-start grid-rows-2
       rounded-xl border-dashed border-2 border-slate-600 bg-white shadow-[0_0_30px_7px] ${props.isUploadInstatntly?"shadow-red-400/50":"shadow-blue-400/50"} md:w-10/12 lg:w-4/6 xl:w-3/6`}
      onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        >
     
            <div className={`rounded-xl grid grid-cols-12 gap-2  items-top justify-start text-sm lg:justify-start w-11/12 `}>
            {props.files.map((file:File, index)=>{
                return  <img src={URL.createObjectURL(file)} key={file.name} title={`${file.name} [${file.size}]`} className={`object-cover  m-1 rounded opacity-${50+Math.ceil(props.progress[index]/20)*10}`}/>
              })}
            </div>
            <div className="bg-neutral-200 rounded-xl flex items-start w=full">
              <ButtonAttach onClick={()=>fileInputRef.current?.click()} disabled={false}/>
              <input multiple={true} accept='image/jpg, image/png, image/jpeg' onChange={handleChangeFile} type="file" id="file" ref={fileInputRef} style={{display: "none"}}/>
              <Textarea
                  className="m-2 -mr-14 bg-transparent pr-14 scrollbar-thin scrollbar-thumb-gray-200"
                  placeholder={props.placeholder ?? t('app.inputMessage')}
                  noBorder
                  value={props.content}
                  onChange={props.onChangeContent}
                  
                />
                <ButtonSend
                  className="m-2 align-bottom"
                  disabled={disabledSend}
                  loading={postingMessage}
                  onClick={props.onSend}
                />
            </div>
     
          
      
      
    </div>
    
  );
};

export default InputChatFileContent;
