import React, { useCallback, useEffect } from 'react';
import { PiList, PiPlus, PiChat } from 'react-icons/pi';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import ChatListDrawer from './components/ChatListDrawer';
import InfoDrawer from './components/InfoDrawer';
import { Authenticator, translations } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import useDrawer from './hooks/useDrawer';
import useInfoDrawer from './hooks/useInfoDrawer';
import ButtonIcon from './components/ButtonIcon';

import useConversation from './hooks/useConversation';
import LazyOutputText from './components/LazyOutputText';
import useChat from './hooks/useChat';
import SnackbarProvider from './providers/SnackbarProvider';
import { useTranslation } from 'react-i18next';
import './i18n';
import { I18n } from 'aws-amplify/utils';
import { useState } from 'react';
import { BrowserRouter  } from 'react-router-dom';
import awsmobile from './configs/aws-exports';
import {Hub } from "aws-amplify/utils";
import { signInWithRedirect, getCurrentUser, AuthUser } from "aws-amplify/auth";
import { withAuthenticator } from '@aws-amplify/ui-react';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';



// function handleSignInClick(customState: string) {
//   console.log("**************** handleSignInClick *************************");
//   signInWithRedirect({

//     provider: "Google",

//     customState

//   });

// }



const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  //console.log("**************** App: React.FC ************************* window.location.hostname:",window.location.hostname);

  

  useEffect(() => {
    // set header title
    document.title = t('app.name');
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // Create a new meta tag for robots
      const metaRobots = document.createElement('meta');
      metaRobots.name = 'robots';
      metaRobots.content = 'noindex';
      document.head.appendChild(metaRobots);

    // Cleanup function to remove the meta tag when the component unmounts
      return () => {
        document.head.removeChild(metaRobots);
      };
  }, []);

  

  Amplify.configure(awsmobile);

  I18n.putVocabularies(translations);
  I18n.setLanguage(i18n.language);

  const { switchOpen: switchDrawer } = useDrawer();
  const { switchOpen:switchInfo } = useInfoDrawer();
  const navigate = useNavigate();

  const { conversationId } = useParams();
  const { getTitle } = useConversation();
  const { isGeneratedTitle } = useChat();

  const onClickNewChat = useCallback(() => {
    //console.log("**************** New chat *************************");
    navigate('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { route,user, signOut,toFederatedSignIn } = useAuthenticator(context => [context.route,context.user]);
  //return route === 'authenticated' ? <Home /> : <Authenticator />
  //console.log("**************** App: route ************************* route:",route,user);

  return (
    route === 'authenticated' ? 
    
          <div className="relative flex h-screen w-screen bg-aws-paper">
            
            <ChatListDrawer
              onSignOut={() => {
                signOut ? signOut() : null;
              }}
            />
  
            <main className="relative min-h-screen flex-1 overflow-y-hidden transition-width">
              <header className="visible flex h-12 w-full items-center bg-aws-squid-ink p-3 text-lg text-aws-font-color-white lg:hidden lg:h-0">
                <button
                  className="mr-2 rounded-full p-2 hover:brightness-50 focus:outline-none focus:ring-1 "
                  onClick={() => {
                    switchDrawer();
                  }}>
                  <PiList />
                </button>
  
                <div className="flex grow justify-center">
                  {isGeneratedTitle ? (
                    <>
                      <LazyOutputText text={getTitle(conversationId ?? '')} />
                    </>
                  ) : (
                    <>{getTitle(conversationId ?? '')}</>
                  )}
                </div>
  
                <ButtonIcon onClick={onClickNewChat}>
                  <PiPlus />
                </ButtonIcon>

                <button
                  className="mr-2 rounded-full p-2 hover:brightness-50 focus:outline-none focus:ring-1 "
                  onClick={() => {
                    switchInfo();
                  }}>
                  <PiChat />
                </button>

              </header>
  
              <div
                className="h-full overflow-hidden overflow-y-auto text-gray-700"
                id="main">
                <SnackbarProvider>
                  <Outlet />
                </SnackbarProvider>
                
              </div>
              
            </main>
            <InfoDrawer/>
            

          </div>

          
 
    
    :

 <Authenticator
    socialProviders={['google']}
    hideSignUp={true}
    signUpAttributes={['email','name']}
    
      components={{
        Header: () => (
          <div className="mb-5 mt-10 flex justify-center text-3xl text-aws-font-color">
            {t('app.name')}
          </div>
        ),
      }}/> 

    // <Authenticator
    // socialProviders={['google']}
    // signUpAttributes={['email','name']}
    //   components={{
    //     Header: () => (
    //       <div className="mb-5 mt-10 flex justify-center text-3xl text-aws-font-color">
    //         {t('app.name')}
    //       </div>
    //     ),
    //   }}>

        
    //   {({ signOut }) => (
    //     <div className="relative flex h-screen w-screen bg-aws-paper">
          
    //       <ChatListDrawer
    //         onSignOut={() => {
    //           signOut ? signOut() : null;
    //         }}
    //       />

    //       <main className="relative min-h-screen flex-1 overflow-y-hidden transition-width">
    //         <header className="visible flex h-12 w-full items-center bg-aws-squid-ink p-3 text-lg text-aws-font-color-white lg:hidden lg:h-0">
    //           <button
    //             className="mr-2 rounded-full p-2 hover:brightness-50 focus:outline-none focus:ring-1 "
    //             onClick={() => {
    //               switchDrawer();
    //             }}>
    //             <PiList />
    //           </button>

    //           <div className="flex grow justify-center">
    //             {isGeneratedTitle ? (
    //               <>
    //                 <LazyOutputText text={getTitle(conversationId ?? '')} />
    //               </>
    //             ) : (
    //               <>{getTitle(conversationId ?? '')}</>
    //             )}
    //           </div>

    //           <ButtonIcon onClick={onClickNewChat}>
    //             <PiPlus />
    //           </ButtonIcon>
    //         </header>

    //         <div
    //           className="h-full overflow-hidden overflow-y-auto text-gray-700"
    //           id="main">
    //           <SnackbarProvider>
    //             <Outlet />
    //           </SnackbarProvider>
    //         </div>
    //       </main>
    //     </div>
    //   )}
      
    // </Authenticator>
  );
};

export default App;
